import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Accordion from "react-bootstrap/Accordion"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import CardDeck from "react-bootstrap/CardDeck"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import DefinitionList from "../../components/DefinitionList"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import Seo from "../../components/Seo"
import { Splide, SplideSlide } from "@splidejs/react-splide"

export const Head = () => {
  return <Seo customTitle="Kartsport" />
}

export default function kartsport() {
  const kartData = [
    {
      name: "Slalom-Kart",
      amount: "2x",
      engine: "Honda GX-200",
      hp: "6,5",
      chassis: "MS-Kart",
      driver: "Alle Fahrer ab 8 Jahren",
    },
    {
      name: "Bambini-Kart",
      amount: "1x",
      engine: "Honda GX-200",
      hp: "6,5",
      chassis: "Mach1",
      driver: "Alle Fahrer bis 7 Jahren",
    },
  ]

  return (
    <Layout>
      <div className="my-5">
        <Container>
          <PageTitle title="Kartsport" />
          <p className="lh-text mb-5 lead font-weight-normal">
            Kartslalom ist der perfekte Einstieg in den Motorsport. So begannen
            viele bekannte Rennfahrer ihre Karrieren. Die Jagd nach der besten
            Rundenzeit im "Dschungel der Pylonen" fördert zudem Geschicklichkeit
            und Koordination.
          </p>
        </Container>

        <Container className="my-5">
          <Splide
            options={{
              rewind: true,
              autoplay: true,
              speed: 1600, // 1.6s
              fixedWidth: "85%",
              gap: "1rem",
            }}
          >
            <SplideSlide>
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="../../images/kartsport/schulhof.jpg"
                alt="Ein aufgebauter Parcour auf dem Schulhof in Böddinghausen."
              />
            </SplideSlide>
            <SplideSlide>
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="../../images/kartsport/kartslalom-1.jpg"
                alt="Ein Kart fährt durch einen aufgebauten Parcour auf dem Schulhof in Böddinghausen."
              />
            </SplideSlide>
            <SplideSlide>
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="../../images/kartsport/kartslalom-2.jpg"
                alt="Ein Kart fährt durch einen aufgebauten Parcour auf dem Schulhof in Böddinghausen."
              />
            </SplideSlide>
          </Splide>
        </Container>

        <div className="bg-light">
          <Container className="py-5">
            <Row className="align-items-center">
              <Col md={12} lg={6}>
                <h2 className="font-weight-bold display-4 text-break text-bg-gradient-blue mb-3 mb-lg-0">
                  Präzision und Geschicklichkeit
                </h2>
              </Col>
              <Col>
                <p className="lead font-weight-normal lh-text text-dark">
                  Geschwindigkeit ist nicht immer ausschlaggebend im Kartslalom.
                  Reaktionsvermögen und Geschicklichkeit gehören auch dazu. Denn
                  das verschieben von Pylonen führt, wenn die Piloten durch den
                  Parcour fahren, zu Zeitstrafen. Diese Fähigkeiten können beim
                  Kartslalom spielerisch erlernt werden.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <h3 className="h2 font-weight-bold mb-3 mt-5">Unsere Karts</h3>
          <p className="lh-text text-dark mb-5">
            Wir besitzen aktuell zwei Slalomkarts der Marke <i>MS-Kart</i>,
            welche mit einem 6,5PS starken Honda-Motor angetrieben werden. Für
            die kleinsten Piloten, besitzen wir zustäzlich noch ein Bambini-Kart
            der Firma <i>Mach1</i>. Alle Karts verfügen über
            Pedalverlängerungen. Zustäzlich kann die Sitzposition verändert
            werden.
          </p>

          <CardDeck>
            {kartData.map((kart, index) => (
              <Card className="border-0" key={index}>
                {index === 0 ? (
                  <StaticImage
                    placeholder="blurred"
                    className="card-img"
                    src="../../images/kartsport-jks-2.jpg"
                    alt="Kartslalom auf dem Schulhof in Böddinghausen mit Herbstlaub."
                  />
                ) : (
                  <StaticImage
                    placeholder="blurred"
                    className="card-img"
                    src="../../images/kartsport-bambini.jpg"
                    alt="Ein Bambini-Kart fährt durch den Parcour auf dem Schulhof in Böddinghausen."
                  />
                )}
                <Card.Body>
                  <Card.Title className="font-weight-bold border-bottom mb-4 pb-2">
                    {kart.name}
                  </Card.Title>
                  <DefinitionList term="Anzahl" desc={kart.amount} />
                  <DefinitionList
                    term="Motor"
                    desc={`${kart.engine} (${kart.hp} PS)`}
                  />
                  <DefinitionList term="Chassis" desc={kart.chassis} />
                  <DefinitionList term="Fahrer" desc={kart.driver} />
                </Card.Body>
              </Card>
            ))}
          </CardDeck>

          <h4 className="h2 font-weight-bold my-5">Häufig gestellte Fragen</h4>

          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Ich würde gerne Kartslalom ausprobieren. Wo kann ich mich
                  melden oder weitere Informationen erhalten?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p className="mb-0">
                    Sie können uns gerne ihre Fragen per{" "}
                    <Link to="/kontakt">E-Mail Adresse</Link> senden oder
                    besuchen Sie uns spontan, bei einem unserer Trainings auf
                    dem Schulhof des Albert-Schweitzer-Gymnasiums.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Welche Ausrüstung brauche ich?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="mb-0">
                    Fahrer brauchen einen Helm sowie Handschuhe. Allerdings
                    können Sie sich einen Helm beim Training ausleihen.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  Wo und wann findet ein Training statt?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="mb-0">
                    Wir trainieren auf dem Schulhof des
                    Albert-Schweitzer-Gymnasiums in Böddinghausen. Trainings
                    finden in der Regel, während der Saison, von Anfang März bis
                    Ende Oktober statt.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  Wird auch bei Regen gefahren?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="mb-0">
                    Bei Regen werden die Karts mit Regenreifen ausgestattet. Bei
                    extremen Wetterlagen wird natürlich nicht gefahren.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  Gibt es ein Mindestalter, um mit dem Kartslalom zu beginnen?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="mb-0">
                    Um an Veranstaltungen teilnehmen zu können, muss der
                    jeweilige Fahrer mindestens 7 Jahre alt sein. Bei unseren
                    Trainings können allerdings auch jüngere Fahrer ihre ersten
                    Runden drehen, wenn Sie die Pedale und Lenkung des Karts
                    unter Kontrolle halten können.
                  </p>
                  <p className="h6 my-3">
                    Klassen für Jugendkartslalom-Veranstaltungen
                  </p>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Klasse</th>
                        <th>Alter</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>7 Jahre</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>8 - 9 Jahre</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>10 - 11 Jahre</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>12 - 13 Jahre</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>14 - 15 Jahre</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>16 - 18 Jahre</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>19 - 23 Jahre</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Ab 24 Jahre</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
    </Layout>
  )
}
