import React from "react"

export default function DefinitionList({ term, desc }) {
  return (
    <dl className="row">
      <dt className="col-12 col-md-4 col-lg-5">{term}</dt>
      <dd className="col">{desc}</dd>
    </dl>
  )
}
